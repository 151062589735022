.defo-contenedor-alerta{
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  background-color: #d9534f;
  border-color: #ebccd1;
  color: #ebccd1;
  position: fixed;
  z-index: 1;
  text-align: left;
  bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  left: 5%;
  opacity: 1;
  display: block;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
  -o-transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
}

.defo-icon-alert{
  width: 25px;
  height: 25px;
  margin-right: 20px;
}

.defo-cerrar-alerta{
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.defo-contenedor-alerta-ocultar{
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  background-color: #d9534f;
  border-color: #ebccd1;
  color: #ebccd1;
  position: fixed;
  z-index: 1;
  text-align: left;
  bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  left: 5%;
  opacity: 0;
  display: none;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
  -o-transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
}

/* Alerta cuando la informacion es enviada correctamente */
.defo-contenedor-alerta-succes{
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  background-color: #d0e4c3;
  border-color: #84c079;
  color: #84c079;
  position: fixed;
  z-index: 1;
  text-align: left;
  bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  left: 5%;
  opacity: 1;
  display: block;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
  -o-transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
}

.defo-contenedor-alerta-ocultar-succes{
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  background-color: #d0e4c3;
  border-color: #84c079;
  color: #84c079;
  position: fixed;
  z-index: 1;
  text-align: left;
  bottom: 10%;
  margin-left: auto;
  margin-right: auto;
  left: 5%;
  opacity: 0;
  display: none;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
  -o-transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear !important;
}

.defo-cerrar-alerta-succes{
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}


.container-principal{
    width: 100%;
    height: 700px;
    padding: 20px;
}

.first-container-documents{
    background: #FFF;
    -webkit-box-shadow: 9px 18px 31px 6px rgba(0,0,0,0.64);
    -moz-box-shadow: 9px 18px 31px 6px rgba(0,0,0,0.64);
    box-shadow: 9px 18px 31px 6px rgba(0,0,0,0.64);
}

.second-container-documents{
    background: #FFF;
    -webkit-box-shadow: 9px 18px 31px 6px rgba(0,0,0,0.64);
    -moz-box-shadow: 9px 18px 31px 6px rgba(0,0,0,0.64);
    box-shadow: 9px 18px 31px 6px rgba(0,0,0,0.64);
}

.sub-second-container{
    padding: 20px;
}

.shadow-container-documents{
    margin-bottom: 70px;
}

.header-documents{
    border: 1px solid #fff;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
    color: #fff;
    vertical-align: middle;
    padding: 20px;
    background: #2c3d5e;
}

.body-documents{
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
}

.footer-documents{
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.footer-search-account-filter{
    position: relative;
    top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.texto-action-red{
  color: #FF0000;
}

.texto-action-green{
  color: #20CB34;
}

.line-column-select-document{
    border-right: 2px solid #142643;
}

.contenedor-boton-confirmar-operaciones{
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

.texto-no-hay-operaciones{
  background: #FFF;
  text-align: center;
  padding: 75px;
  font-size: 40px;
  color: #d7c965;
  font-weight: 300;
}

.button-search-filter{
  padding: 15px;
  padding-top: 5px;
  padding-right: 30px;
  padding-bottom: 5px;
  padding-left: 30px;
  background: #20CB34 !important;
  border: none;
  color: #FFFFFF;
}

.button-search-filter:hover{
    color: #FFFFFF !important;
}

.button-confirm-operations{
    width: 30%;
    background: #20CB34 !important;
    border: none;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.button-confirm-operations:hover{
    color: #FFFFFF !important;
}

.button-confirm-volume{
    width: 50%;
    background: #20CB34 !important;
    border: none;
    color: #FFFFFF;
    margin-top: 25px;
}

.button-confirm-volume:hover{
    color: #FFFFFF !important;
}

.button-search-client{
    border-radius: 100%;
    width: 50px;
    height: 50px;
    border: 2px solid #FFFFFF;
    background-color: transparent;
    position: absolute;
    bottom: 25px;
    right: 30px;
    color: #FFFFFF;
}

.button-search-client:hover{
    color: #000000;
    background-color: #1A3440;
    border: 2px solid #1A3440;
}

.button-approve-doc{
    border-radius: 10px;
    width: 50px;
    height: 50px;
    border: 2px solid #FFFFFF;
    background-color: transparent;
    position: absolute;
    top: 25px;
    right: 30px;
    color: #FFFFFF;
    padding:0;
}

.button-approve-doc:hover{
    color: #000000;
    background-color: #1A3440;
    border: 2px solid #1A3440;
}
.button-approved-doc{
    border-radius: 10px;
    width: 50px;
    height: 50px;
    border: 2px solid #FFFFFF;
    background-color: #FFFFFF;
    position: absolute;
    top: 25px;
    right: 30px;
    color: #FFFFFF;
    padding:0;
}

.button-approved-doc:hover{
    color: #000000;
    background-color: #1A3440;
    border: 2px solid #1A3440;
}

.image-edit-documents{
    width: 44px;
    height: 44px;
    border-radius: 100%;
    margin-left: -15px;
    margin-top: -1px;
}
.image-approve-documents{
    width: 44px;
    height: 44px;
}

.text-title-documents{
    color: black;
    font-size: 17px;
    font-weight: 500;
    width: 100%;
}

.text-volume{
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
}

.input-documents{
    height: 50px;
    color: #516F9B !important;
    font-size: 18px;
    border-radius: 0px !important;
    width: 100%;
    border: none;
    background-color: #EEE;
}

.text-label-documents{
    padding: 14px;
    font-weight: 400;
    font-size: 17px;
}

.image-documents{
    width: 100%;
    height: 100%;
}

.image-documents-modal{
  max-width: 100%;
  max-height: 100%;
}

.container-front-image{
    text-align: center;
    background: #EEE;
    width: 100%;
    height: 350px;
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
}
.container-line-break{
    margin-bottom: 20px;
}

.container-table-results{
    background-color: #FFF;
    width: 100%;
    margin-bottom: 50px;
    padding: 15px;
    -webkit-box-shadow: 9px 18px 31px 6px rgba(0,0,0,0.64);
    -moz-box-shadow: 9px 18px 31px 6px rgba(0,0,0,0.64);
    box-shadow: 9px 18px 31px 6px rgba(0,0,0,0.64);
}

.head-table-documents{
    background: #6783b2;
}

.head-table-documents > tr > th{
  font-size: 15px;
  color: #fff;
  font-weight: 500;
}

.tbody-table-documents td{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:200px;
  font-weight:600;
}

.boton-ver-operaciones-ib{
  text-align: center;
}

.tbody-table-documents{
    color: #000;
}

.no-select-text-documents{
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
}

.spinner-image-document{
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 50px;
}

*, *:before, *:after {
    box-sizing: border-box;
}

body {
    position: relative;
}

.gallery-container {
    padding: 0.9375rem 0;
}

.gallery-container h1 {
    margin: 2rem 0;
    padding: 0;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 6.5vw;
    font-weight: lighter;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.gallery-grid img {
    width: 100%;
    border: 5px solid #fff;
}

.gallery-grid div {
    position: relative;
    cursor: pointer;
}

.gallery-grid div:before, .gallery-grid div:after {
    transition: 0.3s opacity ease;
    opacity: 0;
}

.gallery-grid div:after {
    content: '\02194';
    font-size: 80px;
    position: absolute;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    color: #fff;
    left: 50%;
    top: 50%;
    display: block;
}

.gallery-grid div:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 4px;
    left: 0;
    right: 0;
    background: rgba(34, 34, 34, 0.5);
    display: block;
}

.gallery-grid div:hover:before, .gallery-grid div:hover:after {
    opacity: 1;
    transition: 0.3s opacity ease;
}

.modal {
    position: fixed;
    z-index: 999;
    min-width: auto;
    max-width: calc(100% - 69px);
    max-height: 85%;
    margin-left: 34.5px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    display: block !important;
    border: 10px solid #142643;
    bottom:auto !important;
    overflow: scroll;
}

.modal-overlay {
    position: fixed;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    /* bottom: 0; */
    right: 0;
    left: 0;
}

.modal-body a {
    position: absolute;
    display: inline;
    color: #FFFFFF;
    text-decoration: none;
    line-height: 36px;
    font-size: 30px;
    font-weight: 400;
    background: #142643;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    text-align: center;
}

.modal-body .modal-close {
    right: 0;
    top: 0;
    border-radius: 0 0 0 5px;
}

.modal-body .modal-next, .modal-body .modal-prev {
    right: 0;
    top: calc(50% - 25px);
    border-radius: 5px 0 0 5px;
    height: 50px;
    line-height: 40px;
    font-size: 60px;
}

.modal-body .modal-prev {
    left: 0;
    right: auto;
    border-radius: 0 5px 5px 0;
}

.modal-body {
    position: relative;
    /* background-color: #20CB34; */
    width: 100%;
    height: 100%;
    padding: 0px;
}

.modal-backdrop{
    z-index: 0;
}

#modal-change-image-document .modal-header{
    background-color: #122044;
    color: #A3B2CB;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-bottom: 1px solid #182F53;
}

#modal-change-image-document .modal-content{
    background-color: #1c1c1c;
    color: #A3B2CB;
}

#modal-change-image-document .modal-footer{
    text-align: center;
    border-top: 1px solid #182F53;
}

#modal-change-image-document .modal-body{
    padding: 15px;
}

#modal-change-image-document .modal-footer .btn-default{
    background-color: #2EC93F;
    color: #FFFFFF;
    font-size: 18px;
    border: 1px solid #2EC93F;
    width: 40%;
    text-align: center;
}

.file-input-documents{
    width: 100%;
    cursor: pointer;
    font-size: 18px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    line-height: 0px;
    text-align: center;
    align-content: center;
    justify-content: center;
}

.image-document-preview{
    width: 100%;
    height: 300px;
    border: 5px solid #182E50;
    margin-top: 20px;
    text-align: center;
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
}

.image-document-preview img{
    width: 100%;
    height: 100%;
    display: block;
    max-width: 100%;
}

.title-document-type{
    font-size: 18px;
    font-weight: 300;
    color: #A3B2CB;
    text-align: center;
    margin-bottom: 10px;
}

.text-document-type{
    font-size: 19px;
    font-weight: bold;
    color: #A3B2CB;
}

.content-image-gallery{
    max-height: 100%;
    max-width: 100%;
    text-align: center;
}
.content-image-gallery img{
    height: 100%;
    width: 100%;
    display: inline;
}

.clientCard{
  position: relative;
  border-radius: 10px;
  padding:15px;
  background-color: #181F2F;
  margin-top: 15px;
  text-align: center;
}
.clienCardTitle{
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  color: #d7c965;
}
.clietTableTitle{
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  margin-bottom: 2px;
  color: #d7c965;
}
.closeButton{
  cursor: pointer;
  background-color: #d7c965;
  padding: 5px 10px;
  color: red !important;
  border-radius: 2px;
  position: absolute;
  top:10px;
  right:10px;
}
.closeButton:hover{
  text-decoration: none;
}
.addButton{
  cursor: pointer;
  background-color: #20CB34 !important;
  padding: 5px 10px;
  color: white !important;
  border-radius: 2px;
  top:10px;
  right:10px;
}
.addButton:hover{
  text-decoration: none;
}

.button-search-filter{
  padding: 15px;
  padding-top: 5px;
  padding-right: 30px;
  padding-bottom: 5px;
  padding-left: 30px;
  background: #20CB34 !important;
  border: none;
  color: #FFFFFF;
}
.editSubButton{
  margin-bottom: 10px;
  padding: 15px;
  padding-top: 5px;
  padding-right: 30px;
  padding-bottom: 5px;
  margin:10px;
  padding-left: 30px;
  background: #20CB34 !important;
  border: none;
  color: #FFFFFF;
}
.cabinetModal{
  border:0;
}
.cabinetModal .modal-content{
  border-radius: 15px;
  background-color: transparent;
}

.PayButton{
  border-color: green;
  color: green;
}
.RejectButton{
  border-color: red;
  color: red;
}

.DropMenu{
  text-align: right;
}

.SelectMenu{
  min-width: 120px;
  text-align: left;
}

.cabinetModal .modal-dialog{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.modal-dialog {
  position: relative;
  width: auto !important;
  margin: 10px;
}

.cabinetModal .modal-body{
  background-color: #12294E;
  padding: 10px;
}


@media screen and (max-width: 767px){
  .cabinetModal .modal-dialog{
    position: relative;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%) !important;
  }
}
.modalHeader{
  background: linear-gradient(#206CD8, #10366C);
  color:#FFFFFF;
  text-align: center;
  font-family: monSemi;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-width: 0
}
.modalTitle{
  font-family: monLight;
}
.modalTopImg{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    margin-top: 19px;
    margin-bottom: 12px;
}

.modalText{
      font-family: monMedium;
      color: #DDE4FF;
      margin-bottom: 0px;
      padding: 5px;
}

.modalAccount{
  font-family: monMedium;
  color: #DDE4FF;
  margin-bottom: 0px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px;
}

.modalRow{
  margin-bottom: 10px;
}
.textHeaderActiveTrades{
  font-size: 16px;
  color: #7B9CDA;
}
.tableContainer{
  height:250px;
  overflow: scroll;
}

/* Confirm Modal */
.fade{
  border: none !important;
}
.Modal-Body{
  margin: 1em;
}
.input-button{
  display: flex;
}

.label-check{
  display: flex;
  /* justify-content: center; */
  margin-bottom: 10px;
}
.label-check>label {
  width: auto;
  margin-top: 10px;
  margin-right: 10px;
}
.label-check>button {
  margin-left: 10px;
}
.Form-check{
  width: 8%;
  box-shadow: none !important;
}
.file-control{
  display: none !important;
}
.label-control{
  margin: 0 !important;
  margin-left: 10px !important;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  border: solid 1px;
  padding: 8px 12px;
  font: inherit;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.label-control:hover{
  background-color: rgb(220, 220, 220);
  border-color:#888;

}
.new-role-delete{
  border-radius: 5px;
  background-color: #EC3941;
  color:white;
  border:none;
  padding: 5px 25px;
}

.tabSubtitle{
  color: #5E8AEA ;
}
.DeleteText{
  font-size: 16px;
  text-align: center;
}


/*Ads por email*/
.tableSpace{
  padding-left: 25%;
  padding-right: 25%;
}
.formatDiv{
  width: 100%;
  background-color: #fff;
  border: 1px solid #EBEBEB;
}
.headerDiv{
  width: 100%;
  padding: 10px;
  background-color: #2b2d42;
  display: flex;
  justify-content: center;
}
.mailImgStyle{
  width: 360px;
  height: 70px;
  vertical-align: top;
}
.emailBody{
  width: 100%;
  text-align: center;
  padding: 5% 10%;
}
.emailTextName{
  text-align: left;
  color: #043da9;
  font-size: 20px;
}
.myTextAreaStyle{
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  min-height: 170px;
}
.emailContactTxt{
  font-size: 14px;
  color: #333;
  text-align: left;
}
.emailTextMBFX{
  font-size: 16px;
}
.emailFooterDiv{
  padding: 0% 10%;
  text-align: center;
}
.myEmailText{
  color: #EBEBEB;
  font-size: 13px;
}
