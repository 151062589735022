.button-log-out{
    width: 25%;
    background: #206CD8 !important;
    border: none;
    color: #FFFFFF;
    margin-bottom: 0px;
}

.button-log-out:hover{
    color: #FFFFFF !important;
}

.body-documents{
    vertical-align: middle;
}