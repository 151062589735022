@font-face {
  font-family: patronMedium;
  src:url(fonts/Patron-Medium.ttf);
}
@font-face {
  font-family: patronLight;
  src:url(fonts/Patron-ExtraLight.ttf);
}
@font-face {
  font-family: patronBold;
  src:url(fonts/Patron-Bold.ttf);
}
@font-face {
  font-family: patronRegular;
  src:url(fonts/patron-regular-webfont.otf);
}
@font-face {
  font-family: monLight;
  src:url(fonts/Montserrat-Light.otf);
}
@font-face {
  font-family: monMedium;
  src:url(fonts/Montserrat-Medium.otf);
}
@font-face {
  font-family: monRegular;
  src:url(fonts/Montserrat-Regular.otf);
}
@font-face {
  font-family: monSemi;
  src:url(fonts/Montserrat-SemiBold.otf);
}
@font-face {
  font-family: monBold;
  src:url(fonts/Montserrat-Bold.otf);
}

body{
  background-color: #2B2D42;
}

.centerDiv {
  text-align: center;
}

.backgroundLI{
  overflow: scroll;
  background: center center no-repeat;
  background-size: cover;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 50;
  top: 0;
}

.marginFormInputsLogIn1 {
  margin-top: 45px;
  margin-left: 45px;
  margin-right: 45px;
}

.marginFormInputs{
  margin-left: 45px;
  margin-right: 45px;
}

.widthLogoBannerLogIn {
  width:450px;
  margin-top: 80px;
  margin-bottom: 20px;
}

.buttonRegisterBanner {
  font-family: monMedium;
  font-size: 20px;
  height: 55px;
  border-radius: 20px;
  width: 250px;
  margin-top: 20px;
  background-color: #2B2D42 !important;
  border-width: 1px;
  border-style: solid;
  border-color: #2B2D42 !important;
  color: #fff !important;
  letter-spacing: 1px;
}

.footerTerms{
    color:#fff;
    text-align:center;
    font-size: 11px;
    font-family: monMedium;
}

.sideNav{
  background-color: #2B2D42 !important;
  color:#FFF !important;
  -webkit-box-shadow: 10px 1px 21px 2px rgba(0,0,0,0.64);
  -moz-box-shadow: 10px 1px 21px 2px rgba(0,0,0,0.64);
  box-shadow: 10px 1px 21px 2px rgba(0,0,0,0.64);
}
.sidenav---selected---1EK3y{
  background: #6783b2 !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *{
  color: #FFF !important;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #FFF !important;
}

.sidenav-active{
  background: #206CD8 !important;
}
button{
  outline:none !important;
}

button:active {
  background: transparent !important;
  border: none;
  opacity: 0.5;
  transition: all .5s ease;
}


@media screen and (max-width: 767px){
  .divBackgroundLogIn {
    background-color: white;
    text-align: center;
    border-radius: 10px;
    width: 95%;
    margin-left: 11px;
    padding-bottom: 20px;
  }
  .inputStyleLogIn {
    width:100%;
    height: 55px;
    background-color: rgba(119, 119, 119, 0.2);
    margin-top: 0px;
    font-family: monLight;
    border: none;
    color: #000;
  }
}

@media screen and (min-width: 768px){
  .divBackgroundLogIn {
    background-color: white;
    text-align: center;
    border-radius: 10px;
    padding-bottom: 20px;
  }
  .inputStyleLogIn {
    width:100%;
    height: 55px;
    background-color: rgba(119, 119, 119, 0.2);
    margin-top: 0px;
    font-family: monLight;
    border: none;
    color: #000;
  }
}
